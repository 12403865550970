.home-banner
  padding: 20px 0;
  min-height: 300px;
  background: hero-bg;

  .banner-content
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    .app-name
      height: 100px;
      color: #ffffff;
      font-size: 100px;
      margin-top: 60px;
      font-family: Impact;
      text-transform: uppercase;

    .app-tagline
      padding-top: 70px;
      text-align: center;

      p
        width: 392px;
        color: #ffffff;
        font-size: 26px;
        line-height: 1.45;
        text-align: center;
        font-style: normal;
        font-stretch: normal;
        font-family: Helvetica;
        letter-spacing: normal;
        margin-bottom: 1rem;