.four-o-four
  p
    height: 200px;
    color: #252525;
    font-size: 200px;
    margin-top: 125px;
    font-family: Impact;
    text-transform: uppercase;

.not-found
  p
    font-family: Helvetica;
    text-transform: uppercase;