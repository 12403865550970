.coming-soon
  p
    height: 100px;
    color: #252525;
    font-size: 100px;
    margin-top: 200px;
    font-family: Impact;
    text-transform: uppercase;

.stay-tuned
  p
    font-family: Helvetica;
    text-transform: uppercase;