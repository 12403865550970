.markdown-content

  pre
    margin-top: 1rem !important
    margin-bottom: 1rem !important

  p
    margin-bottom: 1rem

    strong
      font-weight 600

  ul, ol
    margin-left: 25px
    padding-right: 25px
    margin-bottom: 1rem

  hr
    margin-bottom: 2rem

  hr ~ hr
    margin-top: 2rem

  h4, h5
    margin-bottom: .25rem